<!-- <div class="text-center bg-success pt-3 pb-2 text-uppercase text-white ">
  <p class=" sottotitoli  ">
    Iscriviti alla nostra newsletter per rimanere aggiornato su tutte le novita' del Molino Lucconi e del suo shop
    online!
  </p>
</div> -->

<app-sito-carosello [oggetti]="oggetti"></app-sito-carosello>


<div class="text-center mt-4 mb-4">
  <!-- <p class="h1 text-danger my-5">Mulino Lucconi rimarrà chiuso per ferie dal 05/08 al 19/08 compresi </p>
  <div class="text-center pb-5">
    <img width="300vh" src="/assets/img/blog/molino-lucconi-pavia-ferie-2024.png">
  </div> -->
  <p class=" h1"> <mark class="bg-success p-3 text-white retro"> Molino Lucconi </mark>
  </p>
  <p class=" h1 text-uppercase font-light font-italic font-bold mt-5 text-dark-green retro">artigiani mugnai
    dal <b>1908</b></p>
</div>
<div class="col-lg-12">
  <div class=" my-2" *ngIf="articoliUltimi.length>0">
    <div class="text-center my-5">
      <p class=" h1 text-uppercase font-light font-italic font-bold mt-2 retro" style="color: #5e2e8e!important;">Novità e offerte!</p>
    </div>
    <app-articoli-carosello [articoli]="articoliUltimi" [visualizzazione]="1"></app-articoli-carosello>
  </div>
</div>
<!-- <div class="text-center">
  <iframe
    src="https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Fwww.molinolucconi.it%2F&layout=button_count&size=large&width=111&height=28&appId"
    width="111" height="28" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true"
    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
</div> -->
<hr>
<div class="row">
  <div class="col-lg-3 mat-elevation-z20 px-4">
    <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid02bXiqpqpBZYrocVi25BMZ16VJV7bYuJS28YoU6zYup2UgVzSsW5ftXE1U3NVL9wjpl%26id%3D100056363425381&show_text=true&width=500" width="100%" height="601" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid0NUJsTxSaRyB98T6EyEHqfi418mzcm8eLBuk5p2tfWDGhV9kZeVwaP26AYpSYQPhBl%26id%3D100056363425381&show_text=true&width=500" width="100%" height="780" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid027CzWNNAjQs7idfhJF74Uika8q9hiJQiDNpA8Z3dYRfQ2Q7zUr2Qk8vMBM9PzPLKNl%26id%3D100056363425381&show_text=true&width=500" width="100%" height="780" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    
    
<!-- 
    <div class=" my-2" *ngIf="articoliBio.length>0">
      <div class="text-center   my-5">
        <a type="button" (click)="filtra(idCaratteristicaUno)"
          class="h1  btn text-white font-light text-uppercase font-italic viola  "><span
            class="p-5 font-medio sottile">I
            nostri Biologici</span></a>
      </div>
      <app-articoli-carosello [articoli]="articoliBio" [visualizzazione]="1"></app-articoli-carosello>
    </div>

    <div class=" my-2" *ngIf="articoliIta.length>0">
      <div class="text-center  my-5">
        <a type="button" (click)="filtra(idCaratteristicaDue)"
          class="h1 btn text-white font-light text-uppercase font-italic  viola  "><span
            class="p-5 font-medio sottile">100% italiano</span>
        </a>
      </div>
      <app-articoli-carosello [articoli]="articoliIta" [visualizzazione]="1"></app-articoli-carosello>
    </div>

    <div class="text-center mt-5">
      <button type="button" [routerLink]="'/spesa-online'"
        class="btn viola text-white mt-5  my-4 font-alto sottile ">VAI ALLO
        SHOP</button>
    </div> -->
    
  </div>
  <div class="col-lg-6">


    <div class="col-lg-12 mt-5">
      <app-griglia-layout [oggettiInput]="oggettiInput" [nMaxOggettiVisual]="5"></app-griglia-layout>
    </div>

  
  </div>


  <div class="col-lg-3 mat-elevation-z20 p-4">
    <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid026qDsn8pgfd8qcM3oiaarAAbPk9Fr9HgZJ5zqAkuypo1abCXwHGUr4k6zYxQVXz1ol%26id%3D100056363425381&show_text=true&width=500" width="100%" height="780" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid02Kw2zETws1Z6AfMjx7zVqwrFW23wS4fvfYQsGXT2A2b6jYrAB8JtuKekxyMSuJvENl%26id%3D100056363425381&show_text=true&width=500" width="100%" height="564" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid02osPJuSx1ENYicfMg7ExVkGScEr4Cnk4QpEfDvXVZ57ApLPnT7f1Aato9478QboUJl%26id%3D100056363425381&show_text=true&width=500" width="100%" height="722" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
     
  </div>
</div>

<div class="parallax mat-elevation-z20 ">
  <div class="text-center  mt-4 mb-4 vertical-center">
    <p class="h1  text-white font-light text-uppercase font-italic  bg-success  "><span class="p-5">la storia del
        molino</span></p>
  </div>

</div>
<div class=" mat-elevation-z20 text-center  p-40">
  <p class="h4  text-dark-green font-light  justify-content  ">
    Costituita nel 1721, l’impresa del Molino, si lega alla famiglia Lucconi nel 1908 quando il sig. Giuseppe Lucconi, da tutti conosciuto come Giovanni, ci si trasferisce con la famiglia.<br>Dopo anni di duro lavoro come conduttore, nel 1936 Giovanni corona il suo sogno di acquistare l'intero immobile e le attività produttive ad esso collegate.
  </p>
  <button type="button" [routerLink]="'/chi-siamo'" class="btn btn-success px-5 mt-4">Leggi di più</button>
</div>
<section class="row mx-0">
  <div class="mat-elevation-z20 col-lg-2 mt-5 p-4 center">
    <iframe
      src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2F100370494974566%2Fvideos%2F1638852236254010%2F&show_text=false&width=269"
      width="100%" height="476" style="border:none;overflow:hidden" scrolling="no" frameborder="0"
      allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>
  </div>
  <div class=" col-lg-8 container mt-5 text-center">
    <p class="h1  text-white font-light text-uppercase font-italic  bg-success  "><span class="p-5">Articoli dal blog</span></p>
    <div class="row mx-0">
      <div class="col-lg-4 ">
        <div class="card">
          <div class="col-lg-12">
            <img src="/assets/img/blog/molino-lucconi-miele-0050.JPG" class="card-img-top img-fluid"
              alt="miele molino lucconi">
          </div>
          <div class="card-body">
            <h3 class="card-title text-uppercase font-weight-bold text-dark-green">Miele | Molino Lucconi</h3>
            <p class="card-text">Scopri le proprietà curative del Miele!</p>
            <a [routerLink]="'/miele'" class="btn btn-success m-3">Leggi di più </a>
            <p> <a [routerLink]="'/blog-e-news'" class="text-dark-green">Vedi tutti gli articoli</a></p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 ">
        <div class="card">
          <div class="col-lg-12">
            <img src="/assets/img/blog/molino-lucconi-mangime-colombi-2.png" class="card-img-top img-fluid"
              alt="miele molino lucconi">
          </div>
          <div class="card-body">
            <h3 class="card-title text-uppercase font-weight-bold text-dark-green">Mangimi Colombi | Molino Lucconi</h3>
            <p class="card-text">Il moderno sport dei colombi va oltre il semplice allevamento di alcuni colombi. </p>
            <a [routerLink]="'/sport-dei-colombi'" class="btn btn-success m-3">Leggi di più </a>
            <p> <a [routerLink]="'/blog-e-news'" class="text-dark-green">Vedi tutti gli articoli</a></p>
          </div>
        </div>
      </div>
      <div class="col-lg-4">

        <div class="card">
          <div class="col-lg-12">
            <img src="/assets/img/blog/molino-lucconi-riso-carnaroli-di-villareggio-001-01.JPG"
              class="card-img-top img-fluid" alt="riso carnaroli villareggio molino lucconi">
          </div>
          <div class="card-body">
            <h3 class="card-title text-uppercase font-weight-bold text-dark-green">Riso Carnaroli Villareggio</h3>
            <p class="card-text">Scopri le proprietà, le caratteristiche e le origini del "Re" dei Risi.</p>
            <a [routerLink]="'/riso-carnaroli-villareggio'" class="btn btn-success m-3">Leggi di più </a>
            <p> <a [routerLink]="'/blog-e-news'" class="text-dark-green">Vedi tutti gli articoli</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mat-elevation-z20 col-lg-2 mt-5 p-4">

    
    <iframe
      src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2F357419854667559%2Fvideos%2F3109056765779427%2F&show_text=false&width=269"
      width="100%" height="476" style="border:none;overflow:hidden" scrolling="no" frameborder="0"
      allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>
  </div>

  <!-- <a [routerLink]="'/miele'">prova link miele</a> -->
</section>


<!-- carosello novità, best seller e offerte -->
<!-- <section class="container">
    <mat-tab-group  mat-align-tabs="center" class="text-uppercase " >
      <mat-tab label="First">
        <ng-template mat-tab-label >
               <mat-icon class="example-tab-icon">fiber_new</mat-icon>
               Novità
             </ng-template>
       <ng-template matTabContent>
         <div class="container text-center">
         <div  class="row">
          <mat-card class="mat-elevation-z4 m-5">novità 1</mat-card>
          <mat-card class="mat-elevation-z4 m-5">novità 2</mat-card>
          <mat-card class="mat-elevation-z4 m-5">novità 3</mat-card>
         </div>
        </div>
       </ng-template>
     </mat-tab>
     <mat-tab label="Second">
      <ng-template mat-tab-label>
             <mat-icon class="example-tab-icon">emoji_events</mat-icon>
             best seller
           </ng-template>
     <ng-template matTabContent>
       <div class="container text-center">
       <div  class="row">
        <app-articoli-card-grande [articolo]="articolo"></app-articoli-card-grande>
       </div>
      </div>
     </ng-template>
   </mat-tab>
   <mat-tab label="Second">
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon animated infinite tada">new_releases</mat-icon>
      offerte
         </ng-template>
   <ng-template matTabContent>
     <div class="container text-center">
     <div  class="row">
      <mat-card class="mat-elevation-z4 m-5">offerte</mat-card>
      <mat-card class="mat-elevation-z4 m-5">offerte</mat-card>
      <mat-card class="mat-elevation-z4 m-5">offerte</mat-card>
     </div>
    </div>
   </ng-template>
 </mat-tab>
      </mat-tab-group>
</section> -->